import { BlockList } from 'net'
import React, { useState, useEffect } from 'react'

const DegenGen = () => {
  const [animal, setAnimal] = useState('')

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const randAnimal = () => {
    const adjectives = [
      'Able',
      'Back',
      'Bare',
      'Bass',
      'Blue',
      'Bold',
      'Busy',
      'Calm',
      'Cold',
      'Cool',
      'Damp',
      'Dark',
      'Dead',
      'Deaf',
      'Dear',
      'Deep',
      'Dual',
      'Dull',
      'Dumb',
      'Easy',
      'Evil',
      'Fair',
      'Fast',
      'Fine',
      'Firm',
      'Flat',
      'Fond',
      'Foul',
      'Free',
      'Full',
      'Glad',
      'Good',
      'Grey',
      'Grim',
      'Half',
      'Hard',
      'Head',
      'High',
      'Holy',
      'Huge',
      'Just',
      'Keen',
      'Kind',
      'Last',
      'Late',
      'Lazy',
      'Like',
      'Live',
      'Lone',
      'Long',
      'Loud',
      'Main',
      'Male',
      'Mass',
      'Mean',
      'Mere',
      'Mild',
      'Near',
      'Neat',
      'Next',
      'Nice',
      'Okay',
      'Only',
      'Open',
      'Pale',
      'Past',
      'Pink',
      'Poor',
      'Pure',
      'Rare',
      'Real',
      'Rear',
      'Rich',
      'Rude',
      'Safe',
      'Same',
      'Sick',
      'Slim',
      'Slow',
      'Soft',
      'Sole',
      'Sore',
      'Sure',
      'Tall',
      'Then',
      'Thin',
      'Tidy',
      'Tiny',
      'Tory',
      'True',
      'Ugly',
      'Vain',
      'Vast',
      'Very',
      'Vice',
      'Warm',
      'Wary',
      'Weak',
      'Wide',
      'Wild',
      'Wise',
      'Zero'
    ]

    const animals = [
      'Anoa',
      'Asse',
      'Bear',
      'Boar',
      'Buck',
      'Bull',
      'Calf',
      'Cavy',
      'Colt',
      'Cony',
      'Dauw',
      'Deer',
      'Dieb',
      'Douc',
      'Dzho',
      'Euro',
      'Eyra',
      'Fawn',
      'Foal',
      'Gaur',
      'Gilt',
      'Goat',
      'Guib',
      'Gyal',
      'Hare',
      'Hart',
      'Hind',
      'Hogg',
      'Ibex',
      'Joey',
      'Jomo',
      'Kine',
      'Kudu',
      'Lamb',
      'Lion',
      'Lynx',
      'Maki',
      'Mara',
      'Mare',
      'Mico',
      'Mink',
      'Moco',
      'Mohr',
      'Moke',
      'Mole',
      'Mona',
      'Mule',
      'Musk',
      'Napu',
      'Neat',
      'Nowt',
      'Oont',
      'Orca',
      'Oryx',
      'Oxen',
      'Paca',
      'Paco',
      'Pard',
      'Peba',
      'Pika',
      'Pudu',
      'Puma',
      'Quey',
      'Roan',
      'Runt',
      'Rusa',
      'Saki',
      'Seal',
      'Skug',
      'Sore',
      'Tait',
      'Tegg',
      'Titi',
      'Unau',
      'Urus',
      'Urva',
      'Vari',
      'Vole',
      'Wolf',
      'Zati',
      'Zebu',
      'Zobo',
      'Zobu'
    ]

    const adj_rnd = adjectives[Math.floor(Math.random() * adjectives.length)]
    const animal_rnd = animals[Math.floor(Math.random() * animals.length)]

    setAnimal(
      'Degenerate ' +
        capitalizeFirstLetter(adj_rnd) +
        ' ' +
        capitalizeFirstLetter(animal_rnd) +
        's'
    )
  }

  useEffect(() => {
    randAnimal()
  }, [])

  return (
    <div style={{ padding: '60px' }}>
      <button
        style={{
          display: 'inline-block',
          border: 'none',
          padding: '1rem 2rem',
          marginBottom: '40px',
          textDecoration: 'none',
          background: '#0069ed',
          color: '#ffffff',
          fontFamily: 'sans-serif',
          fontSize: '1rem',
          cursor: 'pointer',
          textAlign: 'center',
          transition: 'background 250ms ease-in-out, transform 150ms ease',
          borderRadius: '6px'
        }}
        onClick={randAnimal}
      >
        Generate New Collection
      </button>
      <div
        style={{ fontSize: '30px', fontWeight: '700', marginBottom: '30px' }}
      >
        {animal}
      </div>
      <p>
        brought to you by{' '}
        <a style={{ color: '#0069ed' }} href='https://twitter.com/solanaspaces'>
          @solanaspaces
        </a>
      </p>
    </div>
  )
}

export default DegenGen
